import React, { Component } from 'react'
import Container from './EmersyaConfigurator.style'

export default class EmersyaConfigurator extends Component {
  state = {
    activePreset: 'BLACK',
  }

  changePreset = (preset) => {
    const { applyPreset } = this.props
    if (applyPreset) {
      applyPreset(preset).then(() => {
        this.setState({
          activePreset: preset,
        })
      })
    }
  }

  render() {
    const { isLoading } = this.props
    const { activePreset } = this.state

    return (
      <Container>
        <div id="sf-product">
          <div className="wrap product-detail pt_product-details">
            <h1 className="product-header">JBL Charge 3</h1>

            <div className="desc">
              Full-featured waterproof portable speaker with high-capacity battery to charge your devices
            </div>

            <div className="sf-buttons">
              <div className={`panel panel-0 panel-color ${isLoading ? 'loading' : ''} `}>
                <div
                  onClick={() => this.changePreset('BLACK')}
                  title="Black"
                  className={`control ${activePreset === 'BLACK' && 'active'}`}
                >
                  <div style={{ backgroundColor: 'rgb(25, 25, 25)' }} />
                </div>
                <div
                  onClick={() => this.changePreset('BLUE')}
                  title="Blue"
                  className={`control ${activePreset === 'BLUE' && 'active'}`}
                >
                  <div style={{ backgroundColor: 'rgb(0, 76, 227)' }} />
                </div>
                <div
                  onClick={() => this.changePreset('GREY')}
                  title="Grey"
                  className={`control ${activePreset === 'GREY' && 'active'}`}
                >
                  <div style={{ backgroundColor: 'rgb(173, 163, 163)' }} />
                </div>
                <div
                  onClick={() => this.changePreset('RED')}
                  title="Red"
                  className={`control ${activePreset === 'RED' && 'active'}`}
                >
                  <div style={{ backgroundColor: 'rgb(255, 37, 43)' }} />
                </div>
                <div
                  onClick={() => this.changePreset('GREEN')}
                  title="Green"
                  className={`control ${activePreset === 'GREEN' && 'active'}`}
                >
                  <div style={{ backgroundColor: 'rgb(85, 255, 228)' }} />
                </div>
              </div>
            </div>

            <div className="product-price">
              <span className="price-sales">$99.95</span>
              <span itemProp="price" style={{ display: 'none' }}>
                99.95
              </span>
              <span className="price-each">each</span>
              <span className="price-standard price-strikethrough">$149.95</span>
              <span className="price-off-option price-standard-save"> Save $50</span>
            </div>

            <div className="cartButton_container">
              <button
                className="btn btn-default btn-block add-to-cart"
                id="add-to-cart"
                type="submit"
                title="Add to Cart"
                value="Add to Cart"
              >
                Add to Cart
              </button>
            </div>

            <div className="whybuy">
              <div className="content-asset">
                <div className="row">
                  <a href="/content?ContentID=why-buy-direct" target="_blank">
                    <div className="whybuy-reason  col-xs-4">
                      <i className="icon-shipping-truk" />
                      <span>Free Shipping</span>
                    </div>
                  </a>

                  <a href="/content?ContentID=why-buy-direct" target="_blank">
                    <div className="whybuy-reason  col-xs-4">
                      <i className="icon-arrow-return" />
                      <span>Free Returns</span>
                    </div>
                  </a>

                  <a href="/content?ContentID=why-buy-direct" target="_blank">
                    <div className="whybuy-reason  col-xs-4">
                      <i className="icon-support-headset" />
                      <span>Lifetime Support</span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    )
  }
}
