import React, { Component } from 'react'
import ReactDOM from 'react-dom'

import EmersyaAPI from '../../helpers/EmersyaAPI'
import EmersyaConfigurator from '../EmersyaConfigurator/'
import Container from './EmersyaFrame.style'

export default class EmersyaFrame extends Component {
  constructor() {
    super()
    this.state = {
      isLoaded: false,
      isRotating: false,
    }
    ReactDOM.render(<EmersyaConfigurator isLoading={true} />, document.getElementById('configurator'))
  }

  componentDidMount() {
    if (this.iframe) {
      new EmersyaAPI({ iframe: this.iframe, onload: this.onFrameLoad })
    }
  }

  onFrameLoad = (api) => {
    document.querySelector('.configurator-container .configurator').style.opacity = 1
    api.init().then(() => {
      this.api = api
      ReactDOM.render(
        <EmersyaConfigurator applyPreset={api.applyPreset} isLoading={false} />,
        document.getElementById('configurator')
      )
      this.setState({
        isLoaded: true,
      })
      // this.api.logEvents()
    })
  }

  toggleRotation = (status = !this.state.isRotating) => {
    const updateStateStatus = (isRotating) => {
      this.setState({
        isRotating,
      })
    }
    this.api.toggleRotation(status, updateStateStatus).then((isRotating) => {
      updateStateStatus(isRotating)
    })
  }

  render() {
    const { isRotating, isLoaded } = this.state
    return (
      <Container>
        <div onClick={() => console.log('HELLLLLLO')} className="frameWrapper">
          <iframe
            ref={(ref) => {
              this.iframe = ref
            }}
            title="emersyaIframe"
            id="emersyaIframe"
            src="https://emersya.com/showcase/4BNN3IIHAR"
            webkitallowfullscreen=""
            mozallowfullscreen=""
            allowFullScreen
            width="100%"
            height="490px"
          />
        </div>
        {isLoaded && (
          <div className="navigation">
            <div className="buttons">
              <div className={`but but-rotate ${isRotating ? 'rotating' : ''}`} onClick={() => this.toggleRotation()} />
            </div>
          </div>
        )}
      </Container>
    )
  }
}
