import React from 'react'
import './App.css'
import EmersyaFrame from '../EmersyaFrame'
function App() {
  return (
    <div className="App">
     <EmersyaFrame />
    </div>
  )
}

export default App
