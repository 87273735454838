export default class EmersyaAPI {
  constructor({ iframe, domain = '*', onload = () => {} }) {
    if (this._instance) return this._instance
    this._instance = this
    this._playStatus = false
    this._domain = domain
    this._iframe = iframe
    this._viewerIframe = iframe.contentWindow
    this._iframe.onload = () => {
      this.postMessage({ action: 'registerCallback' })
      this.postMessage({ action: 'getViewerState' })
      onload(this)
    }
  }

  // Promise-based function to wait until iframe api is ready
  init = async () => {
    await this._waitUntilEventResponse(
      (data) =>
        data &&
        data.action === 'onStateChange' &&
        (data.state.viewerState === 'loaded' || data.state.viewerState === 'fallbackloaded')
    )
    this.postMessage({ action: 'pause' })
    return
  }

  // Unique events id generator
  _generateId = () => parseInt((performance.now() + '').replace('.', ''))

  // Method to wait until required message is returned or rejects on any error
  _waitUntilEventResponse = async (dataRuleToResolve) => {
    return new Promise((resolve, reject) => {
      window.addEventListener(
        'message',
        function viewerEventListener(event) {
          if (dataRuleToResolve(event.data)) {
            this._viewerActive = true
            window.removeEventListener('message', viewerEventListener, false)
            resolve(this)
          }
          if (event.data && event.data.action === 'onError') {
            console.warn(event)
            reject(event.data)
          }
        }.bind(this),
        false
      )
    })
  }

  // Listen for and handle the first event response
  _handleOnceUntilResponse = (dataRuleToResolve, successCallback = () => {}) => {
    window.addEventListener(
      'message',
      function viewerEventListener(event) {
        if (dataRuleToResolve(event.data)) {
          this._viewerActive = true
          window.removeEventListener('message', viewerEventListener, false)
          successCallback()
          console.log('successCallback')
        }
        if (event.data && event.data.action === 'onError') {
          console.warn(event)
        }
      }.bind(this),
      false
    )
  }

  // Start console logging all events (used for dev purposes)
  logEvents = () => {
    window.addEventListener('message', (event) => console.log(event), false)
  }

  // Wrapper for default iframe.postMessage method
  postMessage = (props) => {
    return this._viewerIframe.postMessage(
      {
        ...props,
      },
      this._domain
    )
  }

  // Change model preset and return promise on success
  applyPreset = async (preset) => {
    const uniqueId = this._generateId()
    this.postMessage({
      id: uniqueId,
      action: 'applyPreset',
      presetName: preset,
    })
    return await this._waitUntilEventResponse(
      ({ action, callAction, id }) => id === uniqueId && action === 'onSuccess' && callAction === 'applyPreset'
    )
  }

  toggleRotation = async (status, onStatusChange = () => {}) => {
    const uniqueId = this._generateId()
    const newStatus = status !== undefined ? status : !this._playStatus
    const actionValue = newStatus ? 'play' : 'pause'
    this.postMessage({
      id: uniqueId,
      action: actionValue,
    })
    await this._waitUntilEventResponse(
      ({ action, callAction, id }) => id === uniqueId && action === 'onSuccess' && callAction === actionValue
    )
    this._playStatus = newStatus

    // Auto set state to pause if onStop event detected
    this._handleOnceUntilResponse(
      ({ action }) => action === 'onStop',
      () => {
        this._playStatus = false
        onStatusChange(this._playStatus)
      }
    )

    return newStatus
  }
}
