import styled from 'styled-components'

export default styled.div`
  height: 100%;
  .whybuy-reason span {
    font-size: 14px;
  }
  .panel-color {
    & > * {
      /* transition: all 0.5s ease-in-out; */
      width: 36px;
      height: 36px;
      border: 1px solid #e8e8e8;
      position: relative;
      & > div {
        height: 20px;
        width: 20px;
        border-radius: 100%;
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: all 0.3s;
        border: 1px solid #888;
      }
      &:hover {
        & > div {
          transform: scale(1.81) translate(-28%, -28%);
        }
      }
      &:active {
        & > div {
          transform: scale(2.2) translate(-22%, -22%);
        }
      }
    }
    &.loading > * {
      cursor: default;
      opacity: 0.4;
      animation: pulse 1s infinite cubic-bezier(0.34, 0.02, 0.68, 1.65);
      &:nth-of-type(4) {
        animation-delay: 0.1s;
      }
      &:nth-of-type(3) {
        animation-delay: 0.2s;
      }
      &:nth-of-type(5) {
        animation-delay: 0.3s;
      }
      &:nth-of-type(2) {
        animation-delay: 0.4s;
      }
      &:nth-of-type(1) {
        animation-delay: 0.5s;
      }
    }
  }
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
`
