import styled from 'styled-components'

export default styled.div`
  position: relative;
  .but-rotate.rotating {
    opacity: 0.9;
    transform: scale(1.1);
    animation: none;
  }
`
